import React, {useState} from "react"
import Markdown from 'markdown-to-jsx'
import Layout from "../../Layout"
import TopicQuestions from "../../TopicQuestions"
import TopicResultsSummary from "../../TopicResultsSummary"
import TopicCard from "../../TopicCard"
import Button from "../../Button"
import HeroWithColor from "../../HeroWithColor"
import NarrowCallToAction from "../../NarrowCallToAction"
import CallToAction from "../../CallToAction"
import send from "../../../lib/emailer"
import PageBodyContent, { UserContent } from "../../PageBodyContent"
import styled from "styled-components"
import theme from "../../_theme"
import ShareBar from "../../ShareBar"

const Headline = styled.h2`
    color: ${theme.darkText};
    margin-bottom: 15px;
`

function isMobileDevice() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

const Block = styled.div`
  margin-bottom: 45px;
  @media screen and (min-width: ${theme.desktop}){
    margin-bottom: 60px;
  }
`

const useStateWithLocalStorage = localStorageKey => {
  const [resultsStored, setResultsStored] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, resultsStored);
  }, [resultsStored]);
  return [resultsStored, setResultsStored];
};

const ResultsPage = ({
   token,
   title,
   strapline,
   cta_title,
   cta_text,
   cta_button,
   health_questions,
   health_title,
   family_questions,
   family_title,
   home_questions,
   home_title,
   time_questions,
   time_title,
   skills_questions,
   skills_title,
   children_questions,
   children_title,
   banner,
   logo,
   altLogo,
   hero_image,
   hero_alt
 }) => {

  const [familyResultsStored, setFamilyResultsStored] = useStateWithLocalStorage("results_topic_family");
  const [homeResultsStored, setHomeResultsStored] = useStateWithLocalStorage("results_topic_home");
  const [healthResultsStored, setHealthResultsStored] = useStateWithLocalStorage("results_topic_lifestyle");
  const [timeResultsStored, setTimeResultsStored] = useStateWithLocalStorage("results_topic_time");
  const [skillsResultsStored, setSkillsResultsStored] = useStateWithLocalStorage("results_topic_skills");
  const [childrenResultsStored, setChildrenResultsStored] = useStateWithLocalStorage("results_topic_children");

  // const [shareDialogOpen, toggleShareDialog] = useState(false)
  return(
    <Layout banner={banner} logo={logo} altLogo={altLogo}>
        <HeroWithColor
        backgroundColor="white"
        headline={title} deck={strapline} breadcrumbs={[
          {
            href: "/",
            label: "Get ready to foster"
          },{
            label: "Your readiness plan"
          }
        ]}
      />

      <ShareBar
        health_questions={health_questions}
        time_questions={time_questions}
        family_questions={family_questions}
        skills_questions={skills_questions}
        children_questions={children_questions}
        home_questions={home_questions}
        healthResultsStored={healthResultsStored}
        timeResultsStored={timeResultsStored}
        familyResultsStored={familyResultsStored}
        skillsResultsStored={skillsResultsStored}
        childrenResultsStored={childrenResultsStored}
        homeResultsStored={homeResultsStored}
        token={token}
      />

      <PageBodyContent
        backgroundColor={theme.background}
      >
      <h2 className="visually-hidden">Your actions:</h2>

      <TopicCard title="Come to an information event" intro="<p>If you want to learn more about fostering with Buckinghamshire, come along to one of our regular, free events to hear from foster carers, meet our team and get answers to your questions.</p>">
        <Button href="/pages/informationevent">See upcoming events</Button>
      </TopicCard>
        <Block>
            <TopicResultsSummary
              withTitle
              topicSection={home_title}
              resultsArray={homeResultsStored ? homeResultsStored.split('') : false}
              questions={home_questions}
              type="results_topic_home"
              link="home"
            />
        </Block>
        <Block>
            <TopicResultsSummary
              withTitle
              topicSection={family_title}
              resultsArray={familyResultsStored ? familyResultsStored.split('') : false}
              questions={family_questions}
              type="results_topic_family"
              link="family"
            />
        </Block>
        <Block>
            <TopicResultsSummary
              withTitle
              topicSection={skills_title}
              resultsArray={skillsResultsStored ? skillsResultsStored.split('') : false}
              questions={skills_questions}
              type="results_topic_skills"
              link="skills"
            />
        </Block>
        <Block>
            <TopicResultsSummary
              withTitle
              topicSection={health_title}
              resultsArray={healthResultsStored ? healthResultsStored.split('') : false}
              questions={health_questions}
              type="results_topic_lifestyle"
              link="health"
            />
        </Block>
        <Block>
            <TopicResultsSummary
              withTitle
              topicSection={time_title}
              resultsArray={timeResultsStored ? timeResultsStored.split('') : false}
              questions={time_questions}
              type="results_topic_time"
              link="time"
            />
        </Block>
        <Block>
            <TopicResultsSummary
              withTitle
              topicSection={children_title}
              resultsArray={childrenResultsStored ? childrenResultsStored.split('') : false}
              questions={children_questions}
              type="results_topic_children"
              link="children"
            />
        </Block>
      </PageBodyContent>

      <ShareBar
        health_questions={health_questions}
        time_questions={time_questions}
        family_questions={family_questions}
        skills_questions={skills_questions}
        children_questions={children_questions}
        home_questions={home_questions}
        healthResultsStored={healthResultsStored}
        timeResultsStored={timeResultsStored}
        familyResultsStored={familyResultsStored}
        skillsResultsStored={skillsResultsStored}
        childrenResultsStored={childrenResultsStored}
        homeResultsStored={homeResultsStored}
        token={token}
      />

      <NarrowCallToAction
        href="/pages/informationevent"
        headline={cta_title}
        message={cta_text}
        label={cta_button}
      />

      <CallToAction headline="Get in touch">
        <p>If you have any questions about adoption, you can email us at <a href="mailto:fostering@buckinghamshire.gov.uk" target="_blank">fostering@buckinghamshire.gov.uk</a></p>
        <p>or call { isMobileDevice() ? <a href="tel:01296 674 973">01296 674 973</a> : <strong>01296 674 973</strong>}</p>
      </CallToAction>
    </Layout>
  )
}


export default ResultsPage
